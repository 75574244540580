import React,{ReactNode} from 'react';
import {Menu} from '../menu/menu'
// import {Footer} from '../footer/footer'
interface layoutComponent{
    children:ReactNode
}
export const Layout =({children}:layoutComponent) =>{
    return(
        <>
            <Menu/>
            <main>{children}</main>
            {/*<Footer/>*/}
       </>
    )
}

export default Layout;
