import React from "react";
import {ListItem as ListChild, ListItemText, Typography} from "@mui/material";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import BedroomChildOutlinedIcon from '@mui/icons-material/BedroomChildOutlined';
import {IconsList,IListItem} from '../../store/types';

const getIconFromName = (iconName: IconsList) => {
    switch (iconName) {
        case 'adult':
            return <EmojiPeopleIcon className="adultos"/>;
        case 'child':
            return <EmojiPeopleIcon className="ninos"/>;
        case 'night':
            return <NightsStayIcon className="adultos"/>
        case 'room':
            return <BedroomChildOutlinedIcon className="adultos"/>
    }
}
export const ListItem :React.FC<IListItem>=({name,total,iconName}) =>{
    const Icon=getIconFromName(iconName)
    return <ListChild>
                {Icon}
            <ListItemText
                primary={`${name}`}
            />
            <Typography> {total} </Typography>
        </ListChild>

}
