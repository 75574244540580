import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer'
type variant = 'success' | 'warning' | 'info' | 'default' |'error';

interface notifier {
    open: boolean
    variant: variant,
    message: string
}

const initialState: notifier = {
    open: false,
    variant: 'default',
    message: ''
};
const notifierSlice = createSlice({
    name: 'notifier',
    initialState,
    reducers: {
        openNotifier: (state, {payload}: PayloadAction<notifier>) => {
            state.open = payload.open;
            state.variant=payload.variant;
            state.message=payload.message
        },
        closeNotifier: (state ) => {
            state.open=initialState.open
        },
    },
});

export const {openNotifier,closeNotifier} = notifierSlice.actions
export const NotifierReducer = notifierSlice.reducer
export const NotifierSelector = (state: RootState) => state.notifier
