import * as React from 'react';
import Typography from '@mui/material/Typography';
import { hvm2c, estandar_2, estandar_1, vista_mar_1, junior_suite } from '../../assets/images'
import { List } from "@mui/material";
import { ListItemIcon } from '../lisItemIcon/listItemIcon';
import { ICardHotel } from '../../store/types'
import { useTranslation } from "react-i18next";
import { namespaces } from "../../i18n/i18n.constants";
import { useSelector } from "react-redux";
import { BookSelector } from "../../store/app/app";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
export const CardHotel: React.FC<ICardHotel> = ({ title, description, listItem, price, price_offer }) => {
    const { t } = useTranslation([namespaces.rooms, namespaces.rooms_descriptions]);
    const pricePerNight = t("price_per_night", { ns: namespaces.cardHotel });
    const selectDateGuest = t("select_date_guests", { ns: namespaces.cardHotel });
    const {
        currency
    } = useSelector(BookSelector);
    const params = useParams();

    let imageRoom = "#";

    switch (params.room) {
        case "74f251ce-6447-404d-875a-2a11262f426f":
            imageRoom = estandar_2;
            break;
        case "8d35cd33-828a-438a-aa29-b6f71538168c":
            imageRoom = estandar_1;
            break;
        case "c215abfe-7400-4fa5-98ec-023c13d7349b":
            imageRoom = hvm2c;
            break;
        case "c56d5ade-0088-4960-ac82-820cb0ba9963":
            imageRoom = vista_mar_1;
            break;
        case "1ced7413-93c6-4229-946f-a63ff237888c":
            imageRoom = junior_suite;
            break;

        default:
            imageRoom = hvm2c;
            break;
    }


    return (
        <>
            <div className="cont-item">
                <div>
                    <img src={imageRoom} alt="" />
                </div>
                <div className="pd-15">
                    <div className="descripcion-item">
                        <div>
                            <Typography variant={'h6'}>{t(title, { ns: namespaces.rooms })}</Typography>
                            <Typography className="txt-item">{t(title ?? '', { ns: namespaces.rooms_descriptions })}</Typography>

                        </div>
                        <div className="precios-item">
                            <Typography className="font-15">{price_offer !== 0 ? pricePerNight : selectDateGuest} </Typography>
                            <Typography className="tashed">{price_offer !== 0 ? `$${price_offer}` : ''} <small>{currency}</small></Typography>
                            <Typography className="fontBold-20">{price !== 0 ? `$${price}` : ''} <small>{currency}</small></Typography>

                        </div>
                    </div>
                    <div className="D-grid contenido">
                        {listItem.length > 0 && (
                            Array.from({ length: Math.ceil(listItem.length / 2) }, (_, i) => (
                                <div key={i}>
                                    <List className="list-item" key={i}>
                                        {
                                            listItem.slice(i * 2, (i + 1) * 2)
                                                .map((value, index) => (
                                                    <ListItemIcon name={value.id}
                                                        key={index}
                                                        description={value.name} />
                                                ))
                                        }
                                    </List>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
