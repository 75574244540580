import React, { useState,useEffect} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker,FocusedInputShape } from 'react-dates';
import moment from 'moment';
import {BookSelector, DisabledDatesSelector,changeEndDate, changeStartDate, changeNightState, disabledDates} from '../../store/app/app';
import { useSelector, useDispatch } from 'react-redux'
import {RoomSelector} from "../../store/cart/cart";
import {reset_amount} from '../../store/cart/cart';
import {useLocation} from "react-router-dom";
import { openNotifier } from "../../store/notifier/notifier";
import { useTranslation } from 'react-i18next'
import { namespaces } from "../../i18n/i18n.constants";
export const MultiDatePicker =() =>{

    const dispatch = useDispatch()
    const [ focusedInput, setFocusedInput ] = useState<FocusedInputShape | null>(null);
    const [ disabledDatesOfMonth, setDisabledMonth] = useState< Set<unknown> >(new Set([]));
    const { startDate,endDate,id_room} = useSelector(BookSelector);
    const {rooms,amount}=useSelector(RoomSelector);
    const {loading,disabled_dates_month}=useSelector(DisabledDatesSelector);
    const { t } = useTranslation([namespaces.alert_messages])
    let location = useLocation();
    useEffect(() => {

        if(startDate && endDate){
            const start_date = moment(startDate, "YYYY-MM-DD");
            const end_date = moment(endDate, "YYYY-MM-DD");
            const nights = end_date.diff(start_date, 'days');
            dispatch(changeNightState((nights)))
        }
    }, [endDate,startDate]);
    useEffect(() => {
        if(startDate && endDate){
            let start_date = moment(startDate);
            const end_date = moment(endDate);
            const makeCurrentRange = []
            while(start_date.isSameOrBefore(end_date)){
                makeCurrentRange.push(start_date.format("YYYY-MM-DD"))
                start_date = start_date.add(1, 'day')
            }
            if (makeCurrentRange.some((current) => disabledDatesOfMonth.has(current))) {
                dispatch(openNotifier({
                    open: true,
                    variant: "error",
                    message: t('error_range_disabled')
                }))
                dispatch(changeStartDate(null));
                dispatch(changeEndDate(null));
                dispatch(changeNightState((0)))
            }
             // if (disabledDatesOfMonth.has(start_date)) dispatch(changeStartDate(''))
             // if (disabledDatesOfMonth.has(end_date)) dispatch(changeEndDate(''))
        }
    }, [endDate,startDate, disabledDatesOfMonth]);
    useEffect(() => {
        if(id_room!==''){
            // @ts-ignore
            dispatch(disabledDates(
                {start_date_month:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date_month:moment().endOf('month').format('YYYY-MM-DD'),
                    id_room:id_room
                }))
        }
    }, []);
    useEffect(() => {
        let blockedDates = new Set(disabled_dates_month.flat());
        setDisabledMonth(blockedDates)
    }, [disabled_dates_month]);


    return(
        <>
            <DateRangePicker
                startDate={startDate!==null?moment(startDate):startDate}
                //orientation={isMobile?'vertical':'horizontal'}
                numberOfMonths={1}
                disabled={
                    location?.pathname.split('/')[2]==='payment'
                  || location?.pathname.split('/')[2]==='customer'
                }
                isDayBlocked={(day: moment.MomentInput) => disabledDatesOfMonth.has(moment(day).format('YYYY-MM-DD'))}
                startDateId='daterangepicker_start_date'
                startDateAriaLabel='Fecha de Entrada'
                endDate={endDate!==null?moment(endDate):endDate}
                endDateId='daterangepicker_end_date'
                onNextMonthClick={(nexMonth)=>{
                    if(id_room!==''){
                        // @ts-ignore
                        dispatch(disabledDates(
                            {start_date_month:moment().startOf('month').format('YYYY-MM-DD'),
                                end_date_month:nexMonth.endOf('month').format('YYYY-MM-DD'),
                                id_room:id_room
                            }))
                    }

                }}
                onPrevMonthClick={(prevMonth)=>{
                    if(id_room!==''){
                        // @ts-ignore
                        dispatch(disabledDates(
                            {start_date_month:moment().startOf('month').format('YYYY-MM-DD'),
                                end_date_month:prevMonth.endOf('month').format('YYYY-MM-DD'),
                                id_room:id_room
                            }))
                    }

                }}
                onDatesChange={(value)=>{
                    // @ts-ignore
                    if (value.startDate!==null && startDate!==value.startDate){
                        if(amount?.id!=='') {
                            dispatch(reset_amount())
                            dispatch(changeStartDate(moment(value.startDate).format('YYYY-MM-DD')))

                        } else {
                            dispatch(changeStartDate(moment(value.startDate).format('YYYY-MM-DD')))
                        }

                    }
                    // @ts-ignore
                    if (value.endDate!==null && endDate!==value.endDate){
                        if(amount?.id!=='') {
                            dispatch(reset_amount())
                            dispatch(changeEndDate(moment(value.endDate).format('YYYY-MM-DD')))

                        } else {
                            dispatch(changeEndDate(moment(value.endDate).format('YYYY-MM-DD')))
                        }

                    }

                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) =>
                {
                    setFocusedInput(focusedInput)
                }
                }
            />
        </>
    )
}
