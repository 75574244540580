import React from "react";
import {Booking} from '../pages/booking/booking'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import {FinalBookComponent} from '../componets'
export const RoutesComponent = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/en/book" replace/>}>
            </Route>
            <Route path="/es" element={<Navigate to="/es/book" replace/>}>
            </Route>
            <Route path="/en" element={<Navigate to="/es/book" replace/>}>
            </Route>
            <Route path=":lang">
                <Route path="book" element={<Booking/>}>
                    <Route  path=":room" element={<Booking/>}>
                    </Route>
                </Route>
            </Route>
            <Route path=":lang">
                <Route caseSensitive path="customer" element={<Booking/>}>
                </Route>
            </Route>
            <Route path=":lang">
                <Route caseSensitive path="payment" element={<Booking/>}>
                </Route>
            </Route>
            <Route caseSensitive path=":lang/final-book/:folio/:full_name" element={<FinalBookComponent/>}>
            </Route>
            <Route
                path="*"
                element={<Navigate to="/en/book" />}
            />
        </Routes>
    </BrowserRouter>
}
