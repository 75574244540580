import { namespaces } from "./i18n.constants";

export const en = {
    [namespaces.header]: {
        home: "Home",
        rooms: "Rooms",
        benefits: "Benefits",
        Interest: "Interest",
        gallery: "Gallery",
        contact: "Contact",
        lang: "Spanish",
    },
    [namespaces.booking]: {
        entry_date: "Entry date",
        departure_date: "Departure date",
        adults: "Adults",
        childs: "Childs over nine",
        childs2: "Childs under nine",
        nights: "Nights",
        Rooms: "Rooms",
    },
    [namespaces.tab_tittles]: {
        select_your_room: "Select your room",
        contact_information: "Contact information",
        payment_process: "Payment process"
    },
    [namespaces.rooms]: {
        "Standard Room 2 Beds": "Standard Room 2 Beds",
        "Standard Room 1 King Bed": "Standard Room 1 King Bed",
        "Sea View Room 2 Beds": "Sea View Room 2 Beds",
        "Sea View Room 1 Bed": "Sea View Room 1 Bed",
        "Junior Suite Room": "Junior Suite Room",
    },
    [namespaces.rooms_descriptions]: {
        "Standard Room 1 King Bed": "Relax in our room overlooking the pool and plaza, comfortable and comfortable King Size bed. All our rooms have a full bathroom with amenities, minibar, air conditioning with remote control, cable TV and a 32 * flat screen with remote control.",
        "Standard Room 2 Beds": "Relax in our room overlooking the pool and plaza, comfortable and comfortable double beds. All our rooms have a full bathroom with amenities, minibar, air conditioning with remote control, cable TV and a 32 * flat screen with remote control.",
        "Sea View Room King 1 Bed": "Feel the breeze of the Caribbean Sea while you contemplate the sea and the beautiful boardwalk of Cozumel from your private balcony. The sunsets are spectacular from this point of the hotel. The room has a comfortable and comfortable King Size bed. All our rooms have a full bathroom with amenities, minibar, air conditioning with remote control, cable TV and a 32 * flat screen with remote control.",
        "Sea View Room 2 Beds": "Feel the breeze of the Caribbean Sea while you contemplate the sea and the beautiful boardwalk of Cozumel from your private balcony. The sunsets are spectacular from this point of the hotel. The room has two comfortable and comfortable double beds. All our rooms have a full bathroom with amenities, minibar, air conditioning with remote control, cable TV and a 32 * flat screen with remote control.",
        "Junior Suite Room": "Relax in our Junior Suit room equipped with three comfortable and comfortable double beds and overlooking the pool and plaza. All our rooms have a full bathroom with amenities, minibar, air conditioning with remote control, cable TV and a 32 * flat screen with remote control.",
    },
    [namespaces.cardHotel]: {
        price_per_night: "price per night up to",
        select_date_guests: "Select date and guests",
    },
    [namespaces.form]: {
        name: "Name",
        last_name: "Lastname",
        phone: "Phone",
        email: "Email",
        country: "Country",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        enter_your_data: "Enter your data",
        requests_comments: "Request / Comments",
        required: "Required",
        email_invalid: "Email invalid",
    },
    [namespaces.payment]: {
        method: "How do you want to pay?",
        select_complete_reservation: "By selecting complete reservation, I acknowledge that I have read and accept the Rules and Restrictions, Terms of Use and Privacy Terms.",
        paypal: "PayPal allows you to send payments quickly and securely online with a credit card or bank account.",
    },
    [namespaces.final_book]: {
        thanks: "Thank you for your reservation",
        confirmation: "Confirmation",
        booking: "Booking",
        need_info: "If you have any questions or clarifications about your reservation, you can call us at the following number 987 872 0545 / USA Tool free 1-888-309-9988 and we will gladly assist you.",
    },
    [namespaces.modal_id_not_found]: {
        information: "Information",
        select_room: "Select a room from our website to continue",
        selection: "Select",
        book: "Book"
    },
    [namespaces.amenities]: {
        "2 Double beds": "2 Double beds",
        "Pool view": "Pool view",
        "Continental breakfast": "Continental breakfast",
        "Air conditioner": "Air conditioner",
        "Frigo Bar": "Frigo Bar",
        "Wi-Fi": "Wi-Fi",
        "Safe box": "Safe box",
        "Bathroom amenities": "Bathroom amenities",
        "Hairdryer": "Hairdryer",
        "Shower with hot and cold water": "Shower with hot and cold water",
        "1 King Size Bed": "1 King Size Bed",
        "Seaview": "Seaview",
        "3 Double beds": "3 Double beds",
    },
    [namespaces.alert_messages]: {
        error_range_disabled: 'Can\'t select a range between unavailable dates'
    }
};

export const es = {

    [namespaces.header]: {
        home: "Inicio",
        rooms: "Habitaciones",
        benefits: "Beneficios",
        Interest: "Interés",
        gallery: "Galería",
        contact: "Contacto",
        lang: "Ingles",
    },
    [namespaces.booking]: {
        entry_date: "Fecha de entrada",
        departure_date: "Fecha de salida",
        adults: "Adultos",
        childs: "Niños mayores de 9 años",
        childs2: "Niños menores de 9 años",
        nights: "Noches",
        Rooms: "Habitaciones",
    },
    [namespaces.tab_tittles]: {
        select_your_room: "Seleccione su habitación",
        contact_information: "Información de contacto",
        payment_process: "Proceso de pago"
    },
    [namespaces.rooms]: {
        "Standard Room 2 Beds": "Habitación Estándar 2 Camas",
        "Standard Room 1 King Bed": "Habitación Estándar 1 Cama",
        "Sea View Room 2 Beds": "Habitación Vista Del Mar 2 Camas",
        "Sea View Room King 1 Bed": "Habitación Vista Del Mar 1 Cama",
        "Junior Suite Room": "Habitación Junior Suite",
    },
    [namespaces.rooms_descriptions]: {
        "Standard Room 1 King Bed": "Relájese en nuestra habitación con vista a la alberca y plaza, cómoda y confortable cama King Size. Todas nuestras habitaciones cuentan con baño completo con amenidades, frigobar, aire acondicionado con control remoto, televisión por cable y pantalla plana de 32* con control remoto.",
        "Standard Room 2 Beds": "Relájese en nuestra habitación con vista a la alberca y plaza, cómodas y confortables camas matrimoniales. Todas nuestras habitaciones cuentan con baño completo con amenidades, frigobar, aire acondicionado con control remoto, televisión por cable y pantalla plana de 32* con control remoto.",
        "Sea View Room King 1 Bed": "Siente la brisa del Mar Caribe mientras contemplas desde tu balcón privado el mar y el bello malecón de Cozumel. Los atardeceres son espectaculares desde este punto del hotel. La habitación cuenta con una cómoda y confortable cama King Size. Todas nuestras habitaciones cuentan con baño completo con amenidades, frigobar, aire acondicionado con control remoto, televisión por cable y pantalla plana de 32* con control remoto.",
        "Sea View Room 2 Beds": "Siente la brisa del Mar Caribe mientras contemplas desde tu balcón privado el mar y el bello malecón de Cozumel. Los atardeceres son espectaculares desde este punto del hotel. La habitación cuenta con dos cómodas y confortables camas matrimoniales Todas nuestras habitaciones cuentan con baño completo con amenidades, frigobar, aire acondicionado con control remoto, televisión por cable y pantalla plana de 32* con control remoto.",
        "Junior Suite Room": "Relájese en nuestra habitación Junior Suit equipada con tres cómodas y confortables camas matrimoniales y con vista a la alberca y plaza. Todas nuestras habitaciones cuentan con baño completo con amenidades, frigobar, aire acondicionado con control remoto, televisión por cable y pantalla plana de 32* con control remoto.",

    },
    [namespaces.cardHotel]: {
        price_per_night: "Precio por noche de hasta",
        select_date_guests: "Seleccione fecha y huespedes",
    },
    [namespaces.form]: {
        name: "Nombre",
        last_name: "Apellido",
        phone: "Teléfono",
        email: "Correo Electrónico",
        country: "País",
        city: "Ciudad",
        state: "Estado",
        postal_code: "Código Postal",
        enter_your_data: "Ingresa tus datos",
        requests_comments: "Peticiones / Comentarios",
        required: "Requerido",
        email_invalid: "Correo Electrónico Invalido",
    },
    [namespaces.payment]: {
        method: "¿Cómo quieres pagar?",
        select_complete_reservation: "Al seleccionar completar reservacion, reconozco que he leído y acepto las Reglas y Restricciones, Condiciones de Uso y Condiciones de privacidad.",
        paypal: "PayPal permite enviar pagos rápidamente y con seguridad en línea con una tarjeta de crédito o una cuenta bancaria.",
    },
    [namespaces.final_book]: {
        thanks: "¡Gracias por su Reserva",
        confirmation: "Confirmación",
        booking: "de Reserva",
        need_info: " Si tiene alguna duda o aclaración de su reserva, puede llamarnos al siguiente número 987 872 0545 / USA Tool free 1-888-309-9988 y con gusto le atenderemos.",
    },
    [namespaces.modal_id_not_found]: {
        information: "Información",
        select_room: "Seleccione una habitación de nuestro sitio para poder continuar",
        selection: "Seleccionar",
        book: "Reservar"
    },
    [namespaces.amenities]: {
        "2 Double beds": "2 Camas matrimoniales",
        "Pool view": "Vista a la alberca",
        "Continental breakfast": "Desayuno continental",
        "Air conditioner": "Aire acondicionado",
        "Frigo Bar": "Frigo Bar",
        "Wi-Fi": "Wi-Fi",
        "Safe box": "Caja fuerte",
        "Bathroom amenities": "Amenidades de baño",
        "Hairdryer": "Secadora de cabello",
        "Shower with hot and cold water": "Ducha con agua caliente y fría",
        "1 King Size Bed": "2 Camas matrimoniales",
        "Seaview": "Vista al mar",
        "3 Double beds": "3 Camas matrimoniales",
    },
    [namespaces.alert_messages]: {
        error_range_disabled: 'No es posible seleccionar un rango entre fechas no disponibles'
    }
};

