import React, {useEffect, useReducer} from 'react';
import {TabPanel, TabContext, TabList} from '@mui/lab';
// @ts-ignore
import {Tab} from "@mui/material";
import {Form} from "../form/form";
import {Payment} from "../payment/payment";
import {CardHotel} from "../cardHotel/cardHotel";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {reducerNavigate, NavigateEnum} from '../../store/reducers/navigate';
import {useSelector} from "react-redux";
import {BookSelector, CustomerSelector, LoadingAppSelector,changeCurrency} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {AmountSelector, getRoomsAvailability, RoomSelector, RoomsSelector,changeRoomInfo} from '../../store/cart/cart';
import {IRoomAvailability} from '../../store/types/cart/cart';

import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {changePaymentCurrency} from "../../store/payment/payment";


export const TabContain: React.FC<any> = () => {

    const [state_nav, dispatch] = useReducer(reducerNavigate, {
        navigate: {
            tab: 'one',
            location: 'book'
        }
    });
    const dispatchApp = useAppDispatch();
    let location = useLocation();
    let { room,lang } = useParams();
    const { t,i18n } = useTranslation();
    const {
        isValid, startDate, endDate, adult,child,id_room,currency
    } = useSelector(BookSelector);
    const {loading}=useSelector(RoomsSelector);
    const {
        modal,
    } = useSelector(LoadingAppSelector);
    const {validForm} = useSelector(CustomerSelector);
    const {rooms,amount}=useSelector(RoomSelector);
    const {
        id
    } = useSelector(AmountSelector);
    const searchRoom= rooms.find((roomSearch:IRoomAvailability) => roomSearch.id===room);
    let navigate = useNavigate();
    useEffect(() => {
            if(lang){
                i18n.changeLanguage(lang).then();
                const currency=lang==='es'?'MXN':'USD';
                dispatchApp(changeCurrency(currency))
                dispatchApp(changePaymentCurrency(currency))
            }

    }, [lang]);
    useEffect(() => {

            if(searchRoom){
              // @ts-ignore
                dispatch(changeRoomInfo({name:searchRoom?.name,description:searchRoom?.description}))
            }


    }, [searchRoom,id_room]);

    useEffect(() => {
        //i18n.changeLanguage(lang).then();
        const firstUrl = location.pathname.split('/')[2];
        const currencyRoute=lang==='es'?'MXN':'USD';
        if(firstUrl==='book' && (room===amount.id)){
            dispatchApp(getRoomsAvailability({id:(room?room:''),currency:currencyRoute}));
        } else {
            if(id_room!==''){
                dispatchApp(getRoomsAvailability({id:id_room,currency:currencyRoute}));
            }else {
                dispatchApp(getRoomsAvailability({id:(room?room:''),currency:currencyRoute}));
            }

        }

    }, [modal,lang,room,id_room]);


    useEffect(() => {
        const firstUrl = location.pathname.split('/')[2];
        switch (firstUrl) {
            case 'book':
                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: "one", location: 'book'}})
                break;
            case 'customer':
                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: "two", location: 'customer'}})
                break;
            case 'payment':
                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: "three", location: 'payment'}})
                break;
        }
    }, [location.pathname]);

    return (
        <>

            <TabContext value={state_nav.navigate.tab}>
                <div className="cont-tabs">
                    <TabList
                        onChange={(event, value) => {
                            const locationBook=id_room!==''?`book/${id_room}`:'book';
                            const location = value === 'one' ? locationBook : (value === 'two' ? 'customer' : 'payment');
                            if (value === 'one' && isValid) {
                                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: value, location: location}});
                               navigate(`../../${lang}/${location}`, {replace: true})
                            }
                            if (value === 'two' && isValid) {
                                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: value, location: location}});
                                navigate(`../../${lang}/${location}`, {replace: true})
                            }
                            if (value === 'three' && isValid && validForm) {
                                dispatch({type: NavigateEnum.NAVIGATE, payload: {tab: value, location: location}});
                               navigate(`../../${lang}/${location}`, {replace: true})
                            }

                        }}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        className="tabs-content"
                    >
                        <Tab value="one" label={t("select_your_room", { ns: namespaces.tab_tittles })} className="tab-button"/>
                        <Tab value="two" label={t("contact_information", { ns: namespaces.tab_tittles })} className="tab-button"/>
                        <Tab value="three" label={t("payment_process", { ns: namespaces.tab_tittles })} className="tab-button"/>
                    </TabList>
                </div>
                <TabPanel value="one" className="cont-tabPanel">
                    {
                        (searchRoom && room &&  amount.id==='')
                            ?
                                <CardHotel title={searchRoom?.name}
                                           price={0}
                                           price_offer={0}
                                           description={searchRoom?.description}
                                           key={`card-hotel-${searchRoom?.id}`}
                                           listItem={searchRoom?.include_services}
                                />
                            :(startDate && endDate && adult  && amount.id!=='' && room)
                                ?<CardHotel title={amount.name}
                                            price={amount.price_per_night}
                                            price_offer={amount.price_per_night?amount.price_per_night+25:amount.price_per_night}
                                            description={amount.description}
                                            key={`card-hotel-${amount.id}`}
                                            listItem={amount.include_services}
                                />:(startDate && endDate && adult  && rooms.lengh!==0)
                                    ?<CardHotel title={amount.name}
                                                price={amount.price_per_night}
                                                price_offer={amount.price_per_night?amount.price_per_night+25:amount.price_per_night}
                                                description={amount.description}
                                                key={`card-hotel-${amount.id}`}
                                                listItem={amount.include_services}
                                    />:(startDate && endDate && adult && child && amount.id==='')
                                        ?<div></div>:<div></div>
                    }

                </TabPanel>
                <TabPanel value="two" className="cont-tabPanel">
                    <Form/>
                </TabPanel>
                <TabPanel value="three" className="cont-tabPanel">
                    <Payment/>
                </TabPanel>
            </TabContext>
        </>
    );
}
