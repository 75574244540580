import  React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import {LoadingAppSelector, changeModalState, BookSelector} from '../../store/app/app';
import {RoomsSelector} from '../../store/cart/cart';
import {IRoomAvailability} from '../../store/types/cart/cart';
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../store/store";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    "&:focus": {
        outline: "none"
    },
    boxShadow: 24,
    p: 4,
    modal:{
        border:0,
        "&:focus": {
            outline: "none"
        }
    },
};
const styleModal= {
        border:0,
        "&:focus": {
            outline: "none"
        }
};
export const ModalComponent = () => {
    const [ idRoute, setIdRoute ] = React.useState('');
    const {
        modal
    } = useSelector(LoadingAppSelector);
    const {
       currency
    } = useSelector(BookSelector);

    // @ts-ignore
    const changeRoom=(event)=>{
        setIdRoute(event.target.value)
    }
    const {
        rooms
    } = useSelector(RoomsSelector);
    useEffect(() => {

    }, [modal]);
    const { t } = useTranslation([namespaces.rooms,namespaces.modal_id_not_found,namespaces.header]);
    const dispatchApp = useAppDispatch();
    return (
        <Modal
            open={modal}
            sx={styleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="cont-info-modal">
                <div className={"container-modal"}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('information',{ns:'modal_id_not_found'})}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t('select_room',{ns:'modal_id_not_found'})}

                </Typography>
                    {
                        rooms.length>0 &&          <>  <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t('rooms',{ns:'header'})}</InputLabel>
                            <Select
                                labelId="id_room_label"
                                id="demo-simple-select"
                                value={idRoute}
                                label={t('rooms',{ns:'header'})}
                                onChange={(event, child)=>changeRoom(event)}
                            >
                                {
                                    rooms.map((room:IRoomAvailability) => (
                                        <MenuItem key={room.id} value={room.id}> {t(`${room.name}`)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Button variant="contained" disabled={idRoute===''}  className="btn-modal" onClick={()=>{
                        dispatchApp(changeModalState(false))
                            window.location.replace(`${process.env.REACT_APP_URL ?? 'https://bookingshotelvistadelmar.com'}/${currency==='MXN'?'es':'en'}/book/${idRoute}`);
                    }}>  {t('book',{ns:'modal_id_not_found'})}</Button>
                        </>
                    }
                    {
                        rooms.length<=0 &&            <Button variant="contained"  className="btn-modal" onClick={()=>{
                            dispatchApp(changeModalState(false))
                            // const route=currency==='MXN'?'https://www.hotelvistadelmar.com.mx/habitaciones.html':'https://www.hotelvistadelmar.com/bedrooms.html'
                            window.location.replace('https://www.hotelvistadelmar.com/bedrooms.html');
                        }}>  {t('selection',{ns:'modal_id_not_found'})}</Button>
                    }

                </div>
            </Box>

        </Modal>
    );
}
