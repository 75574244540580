import React from 'react';
import {Typography} from "@mui/material";
import {ISummaryTotal} from '../../store/types'


export const TotalSummary:React.FC<ISummaryTotal> = ({currency,total}) => {
    return (
            <div className="total">
                <div>
                    <Typography>Total:</Typography>
                </div>
                <div className="txt-right">
                    <Typography>${total} <small>{currency}</small></Typography>
                </div>
            </div>
    );
}