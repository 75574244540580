import React, {useReducer,useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import { reducerNavigate} from "../../store/reducers/navigate";
import {initialStateCustomer} from "../../store/models/ICustomer"
import {ICheckoutPost,ICheckout} from "../../store/types/checkout/checkout";
import {useFormik} from 'formik';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {BookSelector, changeModalState, CustomerSelector,changeFormValid} from "../../store/app/app";
import {changeFormGuest} from "../../store/app/app"
import {AmountSelector, RoomSelector} from "../../store/cart/cart";
import {useAppDispatch} from "../../store/store";
import {changeDataPayment} from '../../store/payment/payment';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#93289E',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#93289E',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dbdbdb',
        },
        '&:hover fieldset': {
            borderColor: '#b1b1b1',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#93289E',
        },
    },
});

export const Form = () => {
    let navigate= useNavigate();
    let location=useLocation();
    const [state_nav, dispatch] = useReducer(reducerNavigate, { navigate: {
            tab:'two',
            location:'payment'
        } });
    const {
        startDate, endDate,adult,child,isValid,id_room,currency
    } = useSelector(BookSelector);

    const dispatchApp = useAppDispatch();
    const {rooms,amount}=useSelector(RoomSelector);
    const { t,i18n } = useTranslation(namespaces.form);
    let { lang } = useParams();
    const CustomerSchema = Yup.object().shape({
        country: Yup.string()
            .required(t('required')),
        email: Yup.string().email(t('email_invalid')).required(t('required')),
        last_name: Yup.string()
            .required(t('required')),
        name: Yup.string()
            .required(t('required')),
        phone: Yup.string()
            .required(t('required')),
    });
    useEffect(() => {
        if(location?.pathname.split('/')[2]==='customer'){
            if (isValid ){

            } else {
                if(id_room!=='' && id_room){
                    navigate(`../../${lang}/book/${id_room}`, { replace: true })
                } else {
                    navigate(`../../${lang}/book`, { replace: true })
                }
            }
        }

    },[location,isValid,id_room,lang]);
    useEffect(() => {

        if (startDate===''){
            //dispatchApp(changeModalState(true))
        }
    },[]);
    const {name, last_name, phone, country, state, comment,email,validForm}=useSelector(CustomerSelector);
    useEffect(() => {

        if (name!=='' && last_name!==''&& phone!=='' &&country!=='' && email!==''){
            dispatchApp(changeFormValid(true))
        } else {
            if(validForm){
                dispatchApp(changeFormValid(false))
            }
        }
    },[name, last_name, phone,country, state, comment,email]);
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        errors,
        touched
    } = useFormik<any>({
        initialValues: initialStateCustomer,
        validationSchema:CustomerSchema,
        onSubmit: (values: ICheckout, formikHelpers) => {
                const postCheckOut:ICheckoutPost={
                    name: values.name,
                    last_name:values.last_name,
                    email: values.email,
                    country: values.country,
                    phone: values.phone,
                    currency:currency,
                    entry_date: startDate,
                    departure_date:endDate,
                    details: [
                        {
                            room_category_id:amount.id,
                            adult_quantity : adult,
                            child_quantity:child
                        }
                    ]
                }
            if( values.country!=="" && values.email!== ""
               && values.last_name!== "" &&
            values.name!== "" &&
            values.phone!== ""
            ){
                dispatchApp(changeFormGuest(values))
                dispatchApp(changeDataPayment(postCheckOut))
           navigate(`../../${lang}/payment`, { replace: true })
            }

        },

    })
    useEffect(() => {
        if (amount?.id) {
            setValues({
                name:name ,
                last_name:last_name,
                email: email,
                country: country,
                phone: phone,
                entry_date: startDate,
                departure_date:endDate,
                comment:comment,
                currency:currency,
                details: [
                    {
                        room_category_id:amount.id,
                        guests: adult+child
                    }
                ]
            })
        }
    }, []);
    return (
        <>
            <Typography variant="h6">{t('enter_your_data')}</Typography>
            <div className="cont-formulario">
                <div>

                    <CssTextField
                        id="name"
                        label={t('name')}
                        value={values.name}
                        onChange={(event) => {
                            setFieldValue('name', event.currentTarget.value)
                        }}
                        name="name"
                        error={!!errors.name && !!touched.name}
                        helperText={(errors.name && touched.name)?errors.name:''}
                        placeholder={t('name')}
                        multiline
                    />
                </div>
                <div>
                    <CssTextField
                        id="last_name"
                        label={t('last_name')}
                        placeholder={t('last_name')}
                        name={'last_name'}
                        value={values.last_name}
                        error={!!errors.last_name && !!touched.last_name}
                        helperText={(errors.last_name && touched.last_name)?errors.last_name:''}
                        onChange={(event) => {
                            setFieldValue('last_name', event.currentTarget.value)
                        }}
                        multiline
                    />
                </div>

                <div className="grid-form">
                    <div>
                        <CssTextField
                            id="phone"
                            label={t('phone')}
                            placeholder={t('phone')}
                            name={'phone'}
                            value={values.phone}
                            error={!!errors.phone && !!touched.phone}
                            helperText={(errors.phone && touched.phone)?errors.phone:''}
                            onChange={(event) => {
                                setFieldValue('phone', event.currentTarget.value)
                            }}
                            multiline
                        />
                    </div>
                    <div>
                        <CssTextField
                            id="email"
                            label={t('email')}
                            placeholder={t('email')}
                            name={'email'}
                            value={values.email}
                            error={!!errors.email && !!touched.email}
                            helperText={(errors.email  && touched.email)?errors.email:''}
                            onChange={(event) => {
                                setFieldValue('email', event.currentTarget.value)
                            }}
                            multiline
                        />
                    </div>
                </div>

                <div>
                    <CssTextField
                        id="country"
                        label={t('country')}
                        placeholder={t('country')}
                        name={'country'}
                        value={values.country}
                        error={!!errors.country && !!touched.country}
                        helperText={(errors.country && touched.country)?errors.country:''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                    />
                </div>
                <Typography variant="h6">{t('requests_comments')}</Typography>
                <CssTextField
                    id="comment"
                    label={t('requests_comments')}
                    name={'comment'}
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    rows={4}
                />
            </div>
            <div className="cont-btn-contacto">
                <Button className="btn-hotel"
                        onClick={()=>{
                            submitForm().then()
                        }}
                >{i18n?.language==='en'?"Continue":"Continuar"}</Button>
            </div>
        </>
    );
}
