import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from '../rootReducer'
import { ICheckoutPost, IDetailsPostCheckout} from '../types/checkout/checkout'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
interface IPayment{
    id_payment:string,
    data:{
        name: string,
        last_name: string,
        email: string,
        currency:string,
        country: string,
        phone: string,
        entry_date: string,
        departure_date: string,
        details: IDetailsPostCheckout[]
    }
    is_valid:boolean,
    check_term_condition:boolean
}
export const initialState:IPayment  = {
    id_payment: '',
    data:{
        name: '',
        last_name: '',
        email: '',
        currency:'USD',
        country: '',
        phone: '',
        entry_date: '',
        departure_date: '',
        details:[]
    } ,
    is_valid:false,
    check_term_condition:false
}

export const paymentSlice = createSlice({
    initialState,
    name: 'payment',
    reducers: {
        changeDataPayment: (state, {payload}: PayloadAction<ICheckoutPost>) => {
            state.data.name = payload.name
            state.data.last_name= payload.last_name
            state.data.email= payload.email
            state.data.country= payload.country
            state.data.phone= payload.phone
            state.data.currency=payload.currency
            state.data.entry_date= payload.entry_date
            state.data.departure_date= payload.departure_date
            state.data.details=payload.details
        },
        changeIdPayment: (state, {payload}: PayloadAction<string>) => {
            state.id_payment=payload
        },
        changePaymentCurrency: (state, {payload}: PayloadAction<string>) => {
            state.data.currency=payload
        },
        changeTermCond: (state,{payload}: PayloadAction<boolean>) => {
            state.check_term_condition= payload
        }
    },
})


export const paymentSelector = (state: RootState) => {
    return {
        id_payment: state.payment.id_payment,
        data: state.payment.data,
        check_term_condition: state.payment.check_term_condition
    };
};
// export const PaymentReducer = paymentSlice.reducer
export const PaymentReducer = persistReducer({
    storage:storageSession,
    key: 'Payment',
}, paymentSlice.reducer)
export const {
    changeDataPayment,
    changeIdPayment,
    changeTermCond,
    changePaymentCurrency
} = paymentSlice.actions;
