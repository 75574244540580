import React from 'react';
import './App.css';
import { Layout } from './componets'
import { RoutesComponent } from './routes/routes';
import { Provider } from "react-redux";
import { IApp } from './store/app/app';
import { RootState } from './store/rootReducer';
import { persistor } from './store/store';
import { PersistGate } from 'redux-persist/es/integration/react'
import { connect } from "react-redux";
import { BackdropComponent } from '../src/componets';
import { ModalComponent } from '../src/componets';
import "./i18n/i18n";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Notifier from '../src/componets/snackbar/snackbar';
interface IAppProps {
    store: any
    app: IApp
}
const InitialButton = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_SECRET_ID!,
    currency: "USD",
}
class App extends React.Component<IAppProps>  {

    render() {
        const { store } = this.props;
        return (
            <Provider store={store}>
                <PersistGate
                    loading={<BackdropComponent />}
                    persistor={persistor}>
                    <ModalComponent />
                    <Notifier></Notifier>
                    <BackdropComponent />
                    <PayPalScriptProvider options={InitialButton}>
                        <Layout>
                            <RoutesComponent />
                        </Layout>
                    </PayPalScriptProvider>
                </PersistGate>
            </Provider>
        )
    }
}
const mapStateToProps = (state: RootState) => ({
    app: state.app
});
// export default App
export default connect(mapStateToProps)(App);

