import React from "react";
import { List as ListContain } from "@mui/material";
import { ListItem } from '../listItem/listItem'
import { useSelector } from "react-redux";
import { BookSelector, LoadingAppSelector } from "../../store/app/app";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../i18n/i18n.constants";

export const List = () => {
    const { adult, child, child2, nights, room_occupation } = useSelector(BookSelector);
    const { t } = useTranslation(namespaces.booking);
    const {
        capacity_room
    } = useSelector(LoadingAppSelector);
    const capacity_per_room = capacity_room.capacity_per_room;

    return <ListContain className="list-info">
        <ListItem name={t('adults')} total={adult} iconName={"adult"} />
        <ListItem name={t('childs')} total={child} iconName={"child"} />
        <ListItem name={t('childs2')} total={child2} iconName={"child"} />
        <ListItem name={t('nights')} total={nights} iconName={"night"} />
        <ListItem name={t('Rooms')} total={room_occupation} iconName={"room"} />
    </ListContain>
}
