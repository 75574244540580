import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer';
import { ICheckout } from '../types/checkout/checkout';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import axios from 'axios';
const api = process.env.REACT_APP_API_URL;
export interface IApp {
    book_information: {
        startDate: string | null,
        endDate: string | null,
        adult: number,
        child: number,
        child2: number,
        nights: number
        isValid: boolean,
        total_amount: number,
        room_occupation: number,
        currency: string
        id_room: string
    },
    form_guest: {
        name: string,
        last_name: string,
        phone: string,
        country: string,
        city: string,
        email: string,
        state: string
        postal_code: string
        comment: string,
        validForm: boolean
    },
    loading: boolean,
    snackbar: boolean,
    modal: boolean,
    disabled_dates: {
        loading: boolean,
        disabled_dates_month: string[],
    }
    capacity_room: ICapacityRoom
}

interface ICapacityRoom {
    total_capacity: number,
    capacity_per_room: number
}
export const initialState: IApp = {
    book_information: {
        startDate: null,
        endDate: null,
        adult: 1,
        child: 0,
        child2: 0,
        nights: 0,
        currency: 'USD',
        isValid: false,
        total_amount: 0,
        room_occupation: 0,
        id_room: ''
    },
    form_guest: {
        name: '',
        last_name: '',
        phone: '',
        email: '',
        country: '',
        city: '',
        state: '',
        postal_code: '',
        comment: '',
        validForm: false
    },
    loading: false,
    modal: false,
    snackbar: false,
    disabled_dates: {
        loading: false,
        disabled_dates_month: [],
    },
    capacity_room: {
        total_capacity: 1,
        capacity_per_room: 1
    }
}

interface IDisabled {
    start_date_month: string,
    end_date_month: string
}
export const disabledDates = createAsyncThunk<string[],
    { start_date_month: string, end_date_month: string, id_room: string },
    {
        rejectValue: string
    }>(
        'app/disabledDates',
        async (data, thunkApi) => {
            thunkApi.dispatch(changeLoadingState())
            try {
                const request = await axios.get<Array<string>>(`${api}/cart/not-available-dates`, {
                    params: {
                        dateStart: data.start_date_month,
                        dateEnd: data.end_date_month
                    }
                })
                thunkApi.dispatch(changeLoadingState())
                return request.data ?? [];
            } catch (err) {
                thunkApi.dispatch(changeLoadingState())
                // thunkApi.dispatch(closeBackdrop());
                return thunkApi.rejectWithValue('error')
            }
        }
    )
export const AppSlice = createSlice({
    name: 'App',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(disabledDates.pending, state => {
                state.disabled_dates.loading = true
            })
            .addCase(disabledDates.fulfilled, (state, { payload }) => {
                state.disabled_dates.disabled_dates_month = payload
                state.disabled_dates.loading = false
            })
            .addCase(disabledDates.rejected, (state, action) => {
                state.disabled_dates.loading = false
            })
    },
    reducers: {
        changeStartDate: (state, { payload }: PayloadAction<string | null>) => {
            state.book_information.startDate = payload
        },
        changeTotalAmount: (state, { payload }: PayloadAction<number>) => {
            state.book_information.total_amount = payload
        },
        changeRoomOcupation: (state, { payload }: PayloadAction<number>) => {
            state.book_information.room_occupation = payload
        },
        changeIdRoom: (state, { payload }: PayloadAction<string>) => {
            state.book_information.id_room = payload
        },
        changeLoadingState: (state) => {
            state.loading = !state.loading
        },
        changeModalState: (state, { payload }: PayloadAction<boolean>) => {
            state.modal = payload
        },
        changeEndDate: (state, { payload }: PayloadAction<string | null>) => {
            state.book_information.endDate = payload
        },
        changeNightState: (state, { payload }: PayloadAction<number>) => {
            state.book_information.nights = payload
        },
        changeCurrency: (state, { payload }: PayloadAction<string>) => {
            state.book_information.currency = payload
        },
        changeAdultGuest: (state, { payload }: PayloadAction<number>) => {
            state.book_information.adult = payload
        },
        changeChildGuest: (state, { payload }: PayloadAction<number>) => {
            state.book_information.child = payload
        },
        changeChild2Guest: (state, { payload }: PayloadAction<number>) => {
            state.book_information.child2 = payload
        },
        changeTotalCapacity: (state, { payload }: PayloadAction<number>) => {
            state.capacity_room.total_capacity = payload
        },
        changeCapacity: (state, { payload }: PayloadAction<ICapacityRoom>) => {
            const { total_capacity, capacity_per_room } = payload;
            state.capacity_room.total_capacity = total_capacity;
            state.capacity_room.capacity_per_room = capacity_per_room;
        },
        changeValidBook: (state, { payload }: PayloadAction<boolean>) => {
            state.book_information.isValid = payload
        },
        changeFormName: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.name = payload
        },

        changeFormLastName: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.last_name = payload
        },
        changeFormPhone: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.phone = payload
        },
        changeFormCountry: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.country = payload
        },
        changeFormCity: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.city = payload
        },
        changeFormValid: (state, { payload }: PayloadAction<boolean>) => {
            state.form_guest.validForm = payload
        },
        changeFormState: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.state = payload
        },
        changeFormPostalCode: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.postal_code = payload
        },
        changeFormComment: (state, { payload }: PayloadAction<string>) => {
            state.form_guest.comment = payload
        },
        changeLoadingDisabledDates: (state, { payload }: PayloadAction<boolean>) => {
            state.disabled_dates.loading = payload
        },
        changeFormGuest: (state, { payload }: PayloadAction<ICheckout>) => {
            state.form_guest.name = payload.name
            state.form_guest.phone = payload.phone
            state.form_guest.last_name = payload.last_name
            state.form_guest.email = payload.email
            state.form_guest.country = payload.country
            state.form_guest.state = payload.state
            state.form_guest.comment = payload.comment
        }
    },
})


export const {
    changeStartDate,
    changeLoadingDisabledDates,
    changeEndDate,
    changeCurrency,
    changeNightState,
    changeAdultGuest,
    changeChildGuest,
    changeChild2Guest,
    changeValidBook,
    changeFormComment,
    changeFormGuest,
    changeFormState,
    changeLoadingState,
    changeModalState,
    changeCapacity,
    changeTotalAmount,
    changeRoomOcupation,
    changeIdRoom,
    changeFormValid
} = AppSlice.actions


export const AppReducer = persistReducer({
    storage: storageSession,
    key: 'App',
    whitelist: ["book_information", "form_guest"],
    blacklist: ['modal', 'loading', 'currency'],
}, AppSlice.reducer)

export const BookSelector = (state: RootState) => state.app.book_information
export const CustomerSelector = (state: RootState) => state.app.form_guest
export const DisabledDatesSelector = (state: RootState) => state.app.disabled_dates
export const LoadingAppSelector = (state: RootState) => state.app
