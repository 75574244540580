import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {LoadingAppSelector} from '../../store/app/app';
import {useSelector} from "react-redux";
export const BackdropComponent = () => {
    const {
        loading
    } = useSelector(LoadingAppSelector);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}

        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}