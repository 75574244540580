import React, {useEffect,useState} from 'react';
// import {PayPalButton} from 'react-paypal-button-v2';
import {useSelector} from "react-redux";
import {paymentSelector} from "../../store/payment/payment";
import {BookSelector, changeLoadingState, changeModalState, LoadingAppSelector} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {changeIdPayment} from '../../store/payment/payment'
import {useNavigate, useParams} from "react-router-dom";
import {openNotifier} from "../../store/notifier/notifier";
import { PayPalScriptProvider, PayPalButtons,usePayPalScriptReducer } from "@paypal/react-paypal-js";
const api = process.env.REACT_APP_API_URL


export const PaymentButton = () => {

    let navigate = useNavigate();
    let {
        data
    } = useSelector(paymentSelector);
    const {
        loading
    } = useSelector(LoadingAppSelector);
    const {
        currency
    } = useSelector(BookSelector);
    const dispatchApp = useAppDispatch();
    let { lang } = useParams();
    const [{ options }, dispatch] = usePayPalScriptReducer();
    useEffect(() => {

        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    },[currency]);



    const createOrder = () => {
        dispatchApp(changeLoadingState())
        return fetch(`${api}/cart/checkout`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((order) => {
                if(order.id) {
                    dispatchApp(changeIdPayment(order.id))
                    dispatchApp(changeLoadingState())
                    return order.id
                }  else {
                    const error=order?.error
                    dispatchApp(openNotifier({variant: "error", open: true, message:error??'Ops, something unexpected happened, please try again later!'}))
                    dispatchApp(changeLoadingState())
                }
            })
            .catch((err) => {
                dispatchApp(changeLoadingState())
            });
    };
    const onApprove = (data: any) => {
        dispatchApp(changeLoadingState())
        const {orderID} = data
        return fetch(`${api}/cart/checkout/capture/${orderID}`, {
            method: "PUT",
            body: JSON.stringify({
                order_id: orderID,
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((details) => {
                const {
                    folio,
                    full_name,
                } = details;
                if(folio){
                    dispatchApp(changeLoadingState())
                    navigate(`../../${lang}/final-book/${folio}/${full_name}/`, {replace: true,state:{payment:true}})
                    window.sessionStorage.clear();
                } else {
                    dispatchApp(changeLoadingState())
                    const error=details?.error
                    dispatchApp(openNotifier({variant: "error", open: true, message:error??'Ops, something unexpected happened, please try again later!'}))
                }


            })
            .catch((err) => {

                dispatchApp(changeLoadingState())
            });


    }
    // @ts-ignore
    return (
        <div>
                <PayPalButtons
                    createOrder={() => createOrder()}
                    onCancel={()=>{
                        if(loading){
                            dispatchApp(changeLoadingState())
                        }
                    }}
                    onError={()=> {
                        if(loading){
                            dispatchApp(changeLoadingState())
                        }

                    }}
                    onApprove={(OnApproveData,actions) => {
                        return onApprove(OnApproveData);
                    }
                    }
                />

        </div>
    );
};
