import {configureStore, Middleware} from "@reduxjs/toolkit";
import {useSelector as rawUseSelector, TypedUseSelectorHook, useDispatch} from "react-redux";
import axios from "axios";
import {
    persistStore,
} from 'redux-persist';
import rootReducer, {RootState} from './rootReducer'

const customMiddleware: Middleware<{}, RootState> = store => next => action => {
    //const token = store.getState().auth.access_token ?? {token: null};
    // @ts-ignore
    //axios.defaults["headers"]['Authorization'] = token ? `Bearer ${token}` : '';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    axios.defaults.headers.delete['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    next(action);
};

// @ts-ignore
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([customMiddleware]),
    devTools: process.env.NODE_ENV !== 'production'
});


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
export const persistor=persistStore(store);
export default store
