import React from 'react';
import {TabContain,FindRoom} from '../../componets';
import {Grid,Container} from "@mui/material";

export const Booking = () => {

    return (
        <Container maxWidth={"lg"} className="mt-2">
            <Grid container spacing={2} className="cont-100">
                <Grid item md={4} className="card-info">
                    <div className="content-info">
                        <FindRoom/>
                    </div>
                </Grid>
                <Grid item md={8}>
                    <TabContain/>
                </Grid>
            </Grid>
        </Container>
    );
}