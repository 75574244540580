export const namespaces = {
    header: "header",
    booking: "booking",
    tab_tittles: "tab_tittles",
    tab_contain: "tab_contain",
    rooms:"rooms",
    rooms_descriptions:"rooms_descriptions",
    cardHotel:"cardHotel",
    form:'form',
    payment:'payment',
    final_book:'final_book',
    modal:'modal',
    modal_id_not_found:'modal_id_not_found',
    amenities:'amenities',
    alert_messages: 'alert_messages'
};

export const languages = {
    es: "es",
    en: "en",
};
