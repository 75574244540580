type tab = 'one' | 'two' | 'three';
export type url = 'customer' | 'book' | 'payment';

interface INavigate {
    navigate: {
        tab: tab,
        location: string
    }
}

export enum NavigateEnum {
    NAVIGATE = 'NAVIGATE APP',
}

interface NavigateAction {
    type: NavigateEnum;
    payload: {
        tab: tab,
        location: string
    };
}

export const reducerNavigate = (state: INavigate, action: NavigateAction) => {
    const {type, payload} = action;
    switch (type) {
        case NavigateEnum.NAVIGATE:
            return {
                ...state,
                navigate: {
                    tab: payload.tab,
                    location: payload.location
                },
            };
        default:
            return state;
    }
};