import {useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {NotifierSelector,closeNotifier} from "../../store/notifier/notifier";
const Notifier = () => {
    const dispatch = useDispatch();
    const notifierSelector = useSelector(NotifierSelector);
    const {open,variant,message} = notifierSelector;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if(open) {
            enqueueSnackbar(message, {
                variant: variant,
                onExited: (event) => {
                    dispatch(closeNotifier());
                },
            });
        }


    }, [open,closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
